import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';
import conditional from '@/helpers/conditional';

//components
import { Container, InvertedSection } from '@/components/Grid';

//assets
import {
  setHubspotFormIntegration,
  DisclaimerText,
} from '@/vendor/HubspotFormIntegration';

export const HubspotFormTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

const descriptionTextStyle = css`
  margin: 20px 0;

  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    font-size: 12px;
    line-height: 22px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

export const HubspotFormDescription = styled.p`
  ${descriptionTextStyle};
  text-wrap: balance;
`;

const FormContainer = styled(Container)`
  ${screen.md} {
    padding: 0 86px;
  }

  ${screen.lg} {
    padding: 0 82px;
  }

  ${screen.xxlg} {
    padding: 0 230px;
  }
`;

const Label = css`
  width: 100%;
  margin: 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  text-transform: uppercase;

  ${screen.md} {
    margin: 0 5px;
  }

  ${screen.lg} {
    margin: 0 10px;
  }

  ${screen.xxlg} {
    margin: 0 11px;
  }
`;

const Input = css`
  display: block;
  box-sizing: border-box;
  width: 100% !important;
  height: 35px;
  margin: 6px 0;
  padding: 8px 10px;

  color: #4d4e4e;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  background-color: #fff;
  border: 1px solid #333b43;
  border-radius: 4px;
  outline: 0;

  appearance: none;

  &::placeholder {
    color: #dedede;
  }
`;

const Button = css`
  width: 100%;
  height: 44px;
  margin: 10px 0;

  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  background-color: ${({ theme }) => theme.color.greens.stronger};
  border: 0;
  border-radius: 4px;
  cursor: pointer;

  ${screen.md} {
    max-width: 268px;
  }

  ${screen.lg} {
    max-width: 300px;
  }
`;

const ActionsWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Fieldset = css`
  max-width: initial;
`;

const Checkbox = css`
  width: 100% !important;
  margin: 4px auto;

  ${screen.md} {
    width: 50% !important;
  }

  label {
    display: flex;
    align-items: center;
    padding: 8px;

    cursor: pointer;

    span {
      display: inline-flex;
      padding: 0 8px;
    }

    input {
      cursor: pointer;
    }
  }

  &:hover,
  &:focus-within {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
  }
`;

const Wrapper = styled.div`
  & .hs-form fieldset {
    ${Fieldset};
  }

  & .hs-form-field {
    display: flex;
    flex-direction: column;
  }

  & .hs-form fieldset.form-columns-2 .hs-form-field {
    width: 100% !important;

    ${screen.md} {
      width: 50% !important;
    }
  }

  & .hs-form-field .input {
    margin: 0 0 12px !important;

    ${screen.md} {
      margin: 0 4px 20px !important;
    }

    ${screen.lg} {
      margin: 0 9px 20px !important;
    }

    ${screen.xxlg} {
      margin: 0 10px 20px !important;
    }
  }

  & .hs-button-reset,
  & .hs-form .hs-button {
    ${Button};
  }

  & .hs-form label {
    ${Label};
  }

  & .actions {
    ${ActionsWrapper};
  }

  & .hs-form select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
      no-repeat 98% 50%;
  }

  & .hs-form input[type='text'],
  & .hs-form input[type='email'],
  & .hs-form input[type='number'],
  & .hs-form select,
  & .hs-form input[type='tel'] {
    ${Input};
  }

  & .submitted-message {
    ${descriptionTextStyle};
  }

  ul.inputs-list.multi-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    list-style: none;
  }

  & .hs-form-checkbox {
    ${Checkbox};
  }
`;

const Section = styled(InvertedSection)`
  color: #000;

  background-color: ${conditional({
    '#d3d9d9': (props) => props.theme === 'dark',
    '#E6EDF2': (props) => props.theme !== 'dark',
    transparent: (props) => props.theme === 'transparent',
  })};
`;

const HubspotForm = ({
  children,
  portalId,
  formId,
  sfdcCampaignId,
  target = 'integration-hubspot',
  theme,
}) => {
  useEffect(() => {
    setHubspotFormIntegration({
      portalId,
      formId,
      sfdcCampaignId,
      target: `#${target}`,
    });
  }, [formId, portalId, sfdcCampaignId, target]);

  return (
    <Section id="hubspot-form" theme={theme}>
      <Wrapper>
        <Container>{children}</Container>
        <FormContainer>
          <div id={target} />
          <DisclaimerText />
        </FormContainer>
      </Wrapper>
    </Section>
  );
};

HubspotForm.propTypes = {
  children: PropTypes.node,
  portalId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  sfdcCampaignId: PropTypes.string.isRequired,
  target: PropTypes.string,
  theme: PropTypes.string,
};

HubspotForm.defaultProps = {
  children: null,
  target: 'integration-hubspot',
  theme: 'dark',
};

export { HubspotForm };
